import React from 'react'
import Content from '../Content'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import StoryCards from '../StoryCards'

import './index.css'

function getWindowDimensions() {
  if (typeof windowGlobal === 'undefined') {
    return 600;
  } else {
    const { innerWidth: width } = windowGlobal;
    return {
      width,
    };
  }
}

const windowGlobal = typeof window !== 'undefined' && window

const StoriesHomeTemplate = ({ title, subtitle, content, contentComponent, offerings }) => {

  const PageContent = contentComponent || Content
  const { width } = getWindowDimensions()

  const ResponsiveStoryCards = ({}) => {
    if (width <= 770) {
      return <StoryCards gridItems={offerings.blurbs} className={"column is-4 centered"} responsive={true}/>
    }
    else {
      return <StoryCards gridItems={offerings.blurbs} className={"column is-4 centered"} responsive={false}/>
    }
 }
 
  return (
    <div className="story-home-body">
      <div className='container'>
          <div id='storiesheader' className='column is-10 is-offset-1'>
              <h1 className='title story-title'>
                {title}
              </h1>
              <h2 className='subtitle story-subtitle'>
                {subtitle}
              </h2>
          </div>
        </div>
        {/* <iframe id='video' width="48%" height="390px" 
          src="https://www.youtube.com/embed/5f0SESM4eFU?rel=0&loop=1&fs=0&controls=0?modestbranding=1" 
          frameborder="0" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
          allowfullscreen /> */}
        {/* <section className='section3'> */}
          {/* <div className='container'> */}
            <div className='columns story-columns'>
              <div className='column is-10 is-offset-1'>
                <div className='section'>
                  <ResponsiveStoryCards/>
                </div>
              </div>
            {/* </div> */}
          </div>
        {/* </section> */}
    </div>
  )
}

StoriesHomeTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  content: PropTypes.string,
}

export default StoriesHomeTemplate

import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import './index.css'

const StoryCards = ({ gridItems, className, responsive }) => (
  <div className='columns is-multiline is-centered story-cards'>
    {gridItems.map(item => (
      <div key={item.image} className={className} style={{ borderRadius: '5px'}}>
        <Link to={item.linkTo}>
                {responsive
                  ? <section className="section mobile-section storycard-background">
                        <h3 className="is-centered storytitle">{item.title}</h3>
                        <div className="story-img-container">
                          <img className="story-home-img" src={item.image}/>
                        </div>
                        <button className="story-button">Read More</button>
                    </section>
                  : <section className="storycard-background">
                      <h3 className="storytitle">{item.title}</h3>
                      <div className="story-img-container">
                        <img className="story-home-img" src={item.image}/>
                      </div>
                      <button className="story-button">Read More</button>
                    </section>
                }
        </Link>
      </div>
    ))}
  </div>
)

StoryCards.propTypes = {
  gridItems: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.string,
      title: PropTypes.string,
      linkTo: PropTypes.string,
    })
  ),
}

export default StoryCards
